@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-color: white;
  --text-color: black;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #121415;
    --text-color: white;
  }
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Crimson Text', serif;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  outline: none;
}